body {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.container {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.section {
  flex: none;
  width: calc(100% - 80px);
}

.header {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer {
  /* background-color: green; */
  color: white;
  height: 60px;
}

@media (max-width: 768px) {
  .section {
    width: calc(100% - 40px);
  }
}

@supports (height: 100svh) {
  .container {
    min-height: 100svh;
  }
}