.social-links {
  display: flex;
  gap: 15px;
}

.social-links a {
  color: inherit; /* Use the current text color */
  font-size: 24px; /* Adjust size as needed */
  transition: color 0.3s;
}

.social-links a:hover {
  color: #6357C5;
} 