.Cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 550px;
    -webkit-overflow-scrolling: touch;
    padding: 0 40px;
    :last-child {
        margin-right: 0px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}

.Cards-card {
    flex: 0 0 auto;
    cursor: crosshair;
    margin-right: 40px;
    margin-top: auto;
    margin-bottom: auto;
    width: 325px;
    height: 500px;
    box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
}

@media (max-width: 768px) {
    .Cards-container {
        padding: 0 20px;
    }
    .Cards-card {
        margin-right: 20px;
    }
}
  