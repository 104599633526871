.Card-container {
    height: 100%;
    position: relative;
}

.Card-container-bg {
    box-sizing: border-box;
    padding: 50px 20px;
    border-radius: 10px;
    height: 100%;
}

.Card-container-bg-blurred {
    filter: brightness(50%);
    transition: all 0.5s;
}

.Card-container-bg-unblurred {
    filter: brightness(100%);  
    transition: all 0.5s; 
}

.Card-blur {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Card-blur-blurred {
    filter: blur(10px);
    transition: all 0.5s;
}
.Card-blur-unblurred {
    filter: blur(0px) ;
    transition: all 0.5s;
}

.Card-space {
    flex-grow: 1;
}

.Card-text-years {
    margin-bottom: 5px;
}

.Card-logo {
    img {
        object-fit: contain;
        width: 80%;
    }
}

.Card-context {
    position: absolute;
    text-align: center;
    white-space: pre-line;
    line-height: 1.5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;
    color: white;
}

.Card-context-blurred {
    filter: opacity(1);
    transition: all 0.5s;
}
.Card-context-unblurred {
    filter: opacity(0) ;
    transition: all 0.5s;
}